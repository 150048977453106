// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCWQDB0fZdURixqAm_mAq3-sClS7hMKDQ4",
    authDomain: "gulahati-web.firebaseapp.com",
    projectId: "gulahati-web",
    storageBucket: "gulahati-web.appspot.com",
    messagingSenderId: "257711980142",
    appId: "1:257711980142:web:9cdbc51b59e666ef55307f",
    databaseURL: 'https://d2udev.firebaseio.com',
    // apiKey: "AIzaSyAiW9rmqypcti1iW0qEk7JToMpEoOcgiro",
    // authDomain: "d2udev.firebaseapp.com",
    // databaseURL: "https://d2udev.firebaseio.com",
    // projectId: "d2udev",
    // storageBucket: "d2udev.appspot.com",
    // messagingSenderId: "447972569504",
    // appId: "1:447972569504:web:1a7b6bc18e0234176634c5",
    // measurementId: 'G-DZCPQZT1QR'
  }
};
