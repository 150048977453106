import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { functions } from 'firebase';
import { Customer } from '../modals/customer.model';

@Injectable()
export class CustomerService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
  }


  getCustomers() {
    return this.db.collection('customers').snapshotChanges();
  }

  getCustomerWhere(id) {
    return this.db.collection('customers', ref => ref.where('id', '==', id))
      .snapshotChanges();
  }

  getCustomerWhereUserId(id) {
    return this.db.collection('customers', ref => ref.where('userId', '==', id))
      .snapshotChanges();
  }

  create(customer: Customer) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("customers")
        .add(customer)
        .then(res => resolve(''), err => reject(err));
    });
  }

  update(customer: Customer) {
    //delete customer.id;
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection('customers')
        .doc(customer.id).set(customer,{ merge: true })
        .then(res => resolve(''), err => reject(err));
    });
  }

  delete(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.db.collection("customers").doc(id).delete()
        .then(() => resolve())
        .catch(() => reject());
    });
  }
}
