import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Product } from '../modals/product.model';

@Injectable()
export class ProductService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
  }


  getProducts() {
    return this.db.collection('products', ref => ref.where('isAddOn', '==', false)).snapshotChanges();
  }

  getAll() {
    return this.db.collection('products').snapshotChanges();
  }

  getAddOns() {
    return this.db.collection('products', ref => ref.where('isAddOn', '==', true)).snapshotChanges();
  }

  getById(id) {
    return this.db.collection('products').doc(id)
      .snapshotChanges();
  }

  create(product: Product) {
    var newObj = product;
    // if(newObj.addOns){
    // newObj.addOns = product.addOns.map((obj)=> {return Object.assign({}, obj)});
    // }
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("products")
        .add(newObj)
        .then(res => resolve(''), err => reject(err));
    });
  }

  update(product: Product) {
    var newObj = product;
    
    // if(newObj.addOns){
    // newObj.addOns = product.addOns.map((obj)=> {return Object.assign({}, obj)});
    // }
    
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection('products')
        .doc(newObj.id).set(newObj,{ merge: true })
        .then(res => resolve(''), err => reject(err));
    });
  }

  delete(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.db.collection("products").doc(id).delete()
        .then(() => resolve())
        .catch(() => reject());
    });
  }
}
