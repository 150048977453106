import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Order } from '../modals/order.model';

@Injectable()
export class OrderService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
  }

  find(status?: string) {
    if (status) {
      return this.db.collection('orders', ref => ref.where('status', '==', status)).snapshotChanges();
    }
    return this.db.collection('orders').snapshotChanges();
  }

  GetUpcomingOrders() {
      return this.db.collection('orders', ref => ref.where('deliveryDate', '>=', new Date())).snapshotChanges();
  }

  get(id?: string) {
    const db = firebase.firestore();
    return db.collection("orders").doc(id).get();    
  }


  create(order: Order) {

    var newObj = order;
    newObj.orderItems = order.orderItems
    .map((obj)=> {
      var item = Object.assign({}, obj);
      // if(obj.addOns){
      //   item.addOns =  obj.addOns.map((obj)=> {return Object.assign({}, obj)})
      // }
      // else {
      //   item.addOns = [];
      // }
      return item;
    });


    return new Promise<void>((resolve, reject) => {
      const db = firebase.firestore();
      db.collection("orders").add(order)
        .then(() => resolve())
        .catch((ex) => {  
          console.log(ex);
          reject()
        });
    });

    // return new Promise<void>((resolve, reject) => {
    //   const db = firebase.firestore();
    //   // Get a new write batch
    //   let batch = db.batch();

    //   // Set the value of parent
    //   const parentDocRef = db.collection("orders").doc();
    //   batch.set(parentDocRef, order);

    //   //Set the value of a sub-collection doc

    //   const parentDocId = parentDocRef.id;

    //   const subCollectionDocRef = db.collection("orders").doc(parentDocId).collection("orderLines").doc();
    //   batch.set(subCollectionDocRef, order.orderItems);

    //   // Commit the batch
    //   batch.commit()
    //     .then(() => resolve())
    //     .catch(() => reject());
    // });
  }

  update(order: Order) {
    var newObj = order;
    newObj.orderItems = order.orderItems
    .map((obj)=> {
      var item = Object.assign({}, obj);
      // if(obj.addOns){
      //   item.addOns =  obj.addOns.map((obj)=> {return Object.assign({}, obj)})
      // }
      // else {
      //   item.addOns = [];
      // }
      return item;
    });

    return new Promise<void>((resolve, reject) => {
      const db = firebase.firestore();
      db.collection("orders").doc(newObj.id).set(newObj)
        .then(() => resolve())
        .catch((ex) => {  
          console.log(ex);
          reject()
        });
    });
  }

  delete(id: string) {
    return new Promise<void>((resolve, reject) => {
      const db = firebase.firestore();
      db.collection("orders").doc(id).delete()
        .then(() => resolve())
        .catch(() => reject());
    });
  }
}
