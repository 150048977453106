export class FirebaseUserModel {
  email: string;
  password: string;
  image: string;
  name: string;
  isAuthenticated: boolean;
  isActive: boolean;

  constructor(){
    this.image = "";
    this.name = "";
  }
}

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}
