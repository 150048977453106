import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {functions} from 'firebase';

@Injectable()
export class UserService {

    constructor(
     public db: AngularFirestore,
     public afAuth: AngularFireAuth,
   ) {
   }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No dashboard logged in');
        }
      });
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: value.name,
        photoURL: user.photoURL
      }).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  getUsers() {
    return this.db.collection('users').snapshotChanges();
  }

  getCustomers() {
    return this.db.collection('customers').snapshotChanges();
  }

  getRiders() {
    return this.db.collection('riders').snapshotChanges();
  }

  getCustomerWhere(id) {
    return this.db.collection('customers', ref => ref.where('id', '==', id))
      .snapshotChanges();
  }

  getCustomerWhereUserId(id) {
    return this.db.collection('customers', ref => ref.where('userId', '==', id))
      .snapshotChanges();
  }

  getRiderWhereUserId(id) {
    return this.db.collection('riders', ref => ref.where('userId', '==', id))
      .snapshotChanges();
  }


  registerRider(value) {
    const user = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      mobile: value.mobile,
      role: value.role,
      earnings: value.earnings,
      isBlocked: value.isBlocked,
      isTacVerified: false,
      isTemporaryPassword: false,
      createdOn: new Date(),
      profilePicUrl: value.profilePicture || '',
    };
    return new Promise<any>((resolve, reject) => {
      const createUser = functions().httpsCallable('createUser');
      createUser(value).then((res: any) => {
        const db = firebase.firestore();
        db.collection('users').add(user)
          .then((resp: any) => {
            const customer = {
              firstName: value.firstName,
              lastName: value.lastName,
              profilePicUrl: value.profilePicture || '',
              userId: resp.id
            };
            db.collection('riders').add(customer)
              .then(() => resolve("success"))
              .catch(err => reject(err));
          }).catch(err => reject(err));
      }).catch(err => reject(err));
    });
  }

  updateUser(value) {
    const user = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      mobile: value.mobile,
      role: value.role,
      profilePicUrl: value.profilePicture || '',
    };
    return new Promise<any>((resolve, reject) => {
      const db = firebase.firestore();
          this.db.collection('customers', ref =>
            ref.where('userId', '==', value.id))
            .snapshotChanges().subscribe(data => {
              const uid = data.map(e => e.payload.doc.id);
              if (uid.length > 0) {
                db.collection('customers').doc(uid[0]).update(user)
                  .then(() => resolve(""))
                  .catch(err => reject(err));
              } else resolve("")
            });
    });
  }

  createCustomer(value) {
    const user = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      mobile: value.mobile,
      role: value.role,
      profilePicUrl: value.profilePicture || '',
    };
    return new Promise<any>((resolve, reject) => {
      //const db = firebase.firestore();
      this.db.collection('customers').add(user).then(() => resolve(""))
      .catch(err => reject(err));
    });
  }

  updateRider(value) {
    const user = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      mobile: value.mobile,
      role: value.role,
      earnings: value.earnings,
      isBlocked: value.isBlocked,
      profilePicUrl: value.profilePicture || '',
    };
    return new Promise<any>((resolve, reject) => {
      const db = firebase.firestore();

      db.collection('users').doc(value.id).update(user)
        .then((resp: any) => {
          const customer = {
            firstName: value.firstName,
            lastName: value.lastName,
            profilePicUrl: value.profilePicture || '',
          };
          this.db.collection('riders', ref =>
            ref.where('userId', '==', value.id))
            .snapshotChanges().subscribe(data => {
            const uid = data.map(e => e.payload.doc.id);
            if (uid.length > 0) {
              db.collection('riders').doc(uid[0]).update(customer)
                .then(() => resolve(""))
                .catch(err => reject(err));
            }
          });
        }).catch(err => reject(err));
    });
  }

  block(value) {
    return new Promise<void>((resolve, reject) => {
      const isBlocked = { isBlocked: value.isBlocked }
      const db = firebase.firestore();
      db.collection('users').doc(value.id).update(isBlocked)
        .then(() => resolve())
        .catch(err => reject(err));
    });
  }

  updatePassword(user) {
    return new Promise<void>((resolve, reject) => {
      const db = firebase.firestore();
      db.collection('users').doc(user.id).set(user)
        .then(() => resolve())
        .catch(() => reject());
    });
  }
}
